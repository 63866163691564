<template>
  <b-card>
    <d-collapse-section
      title="views.playground.index.parameters.restrictions.title"
      :model="collapse"
      accordion="restrictions"
      @on:open-or-close="collapse = ! collapse"
    >
      <div slot="body">
        <div class="mt-2 wrapper">
          <b-row
            v-if="isDisabled"
            class="mb-2 mt-2"
          >
            <b-col class="light-danger-col-title" cols="12">
              <span>
                <i class="icofont icofont-exclamation-tringle"></i>
              </span>
              {{ networkDescription }}
            </b-col>
          </b-row>
          <b-row>
            <b-col :cols="cols">
              <restriction-list
                v-if="loadedDependencies"
                :reload="reload"
                :activities="activities"
                :subscriptionPlans="subscriptionPlans"
                @on:restriction:edit="editRestriction"
                @reset:restriction-form="resetRestrictionModel"
              />
            </b-col>
            <div v-if="cols === '5'" class="split-layout__divider">
              <div class="split-layout__rule"></div>
              <div class="split-layout__rule"></div>
            </div>
            <b-col>
              <form-details
                v-if="restriction"
                :key="'detail-restriction' + detailFormKey"
                :clubs="clubs"
                :is-disabled="isDisabled"
                :time-blocks="timeBlocks"
                :restriction="restriction"
                :load-time-blocks="loadTimeBlocks"
                @on:restriction-added="resetRestrictionModel()"
                @reset:restriction-form="resetRestrictionModel()"
              />
            </b-col>
          </b-row>
        </div>
      </div>
    </d-collapse-section>
  </b-card>
</template>
<script>
import Restriction from "@/classes/doinsport/Restriction";
import {getCustomActivities} from "@api/doinsport/services/activity/activity.api";
import {getSubscriptionPlans} from "@api/doinsport/services/subscription-plans/subscription-plans.api";
import {getRestriction} from "@api/doinsport/services/restrictions/restriction.api";
import {isNotUndefinedAndNotNull} from "@/utils/classes";
import {getNetworkClubs} from "@api/doinsport/services/club/network/network-club.api";
import {ROLE_CLUB_NETWORK_OWNER} from "@/utils/role";
import {ALL_NETWORK_CLUB_LIST, NETWORK_CLUB_SELECTION, ONLY_CURRENT_CLUB} from "@/constants/network/network-sharing";

export default {
  data: () => ({
    collapse: false,
    reload: false,
    loadTimeBlocks: false,
    detailFormKey: 1,
    loadedDependencies: false,
    restriction: null,
    activities: [],
    clubs: [],
    timeBlocks: [],
    subscriptionPlans: [],
  }),
  components: {
    RestrictionList: () => import('./List'),
    FormDetails: () => import('./Details')
  },
  computed: {
    networkDescription() {
      const userDescription = this.$t('views.playground.index.parameters.restrictions.is-disabled-description');
      const networkDescription = this.$t('views.playground.index.parameters.restrictions.is-disabled-network-description', {club: this.restriction.club.name});

      return this.hasNetworkRole ? networkDescription : userDescription;
    },
    currentClub() {
      return this.$store.getters['auth/currentClub'];
    },
    isDisabled() {
      if (this.restriction.id) {
        switch (this.restriction.networkSharingFor) {
          case ONLY_CURRENT_CLUB:
            return false;
          case NETWORK_CLUB_SELECTION:
          case ALL_NETWORK_CLUB_LIST:
            if (!this.hasNetworkRole) {
              return true;
            } else {
              if (this.currentClub['@id'] !== this.restriction.club['@id']) {
                return true;
              }
            }
        }
      }

      return false;
    },
    hasNetworkRole() {
      return this.$hasRole(ROLE_CLUB_NETWORK_OWNER);
    },
    cols() {
      return this.$store.getters["layout/getInnerWidth"] <= 991 ? '12' : '5';
    }
  },
  methods: {
    editRestriction(id) {
      getRestriction(id)
        .then((response) => {
          this.restriction = new Restriction(response.data, {deserialize: true});
          const activitiesValues = [];

          for (const activity of this.restriction.activities) {
            const findActivityAttributes = this.activities.find(el => el['@id'] === activity);

            if (isNotUndefinedAndNotNull(findActivityAttributes)) {
              activitiesValues.push({
                code: findActivityAttributes.id,
                label: findActivityAttributes.name
              });
            }

          }
          this.restriction.activities = activitiesValues;

          if (this.restriction.target === 'selected_subscribers') {
            const subscriptionPlans = [];

            for (const subscription of this.restriction.targetSubscriptionPlans) {
              const findSubscriptionPlanAttributes = this.subscriptionPlans.find(el => el['@id'] === subscription);

              if (isNotUndefinedAndNotNull(findSubscriptionPlanAttributes)) {
                subscriptionPlans.push({
                  code: findSubscriptionPlanAttributes.id,
                  label: findSubscriptionPlanAttributes.name
                });
              }
            }

            this.restriction.targetSubscriptionPlans = subscriptionPlans;
          }
        })
        .finally(() => {
          this.loadTimeBlocks = !this.loadTimeBlocks;
        })
      ;
    },
    resetRestrictionModel() {
      this.reload = !this.reload;
      this.restriction = new Restriction();
      this.detailFormKey += this.detailFormKey;
    },
    loadDependencies() {
      const requests = [];

      requests.push(getCustomActivities(500, 1, ''));
      requests.push(getSubscriptionPlans(500, 1, '', null));

      Promise.all(requests)
        .then((responses) => {
          for (const response of responses) {
            if (response.data['@context'] === '/contexts/Activity') {
              this.activities = response.data['hydra:member'];
            } else {
              this.subscriptionPlans = response.data['hydra:member'];
            }
          }
        })
        .finally(() => {
          this.loadedDependencies = true;
        })
      ;
    },
    loadNetworkClubs() {
      if (this.hasNetworkRole) {
        getNetworkClubs(this.currentClub.network.id, '')
          .then((response) => {
            this.clubs = response.data['hydra:member'];
          })
        ;
      }
    },
  },
  created() {
    this.loadDependencies();
    this.loadNetworkClubs();
    this.restriction = new Restriction();
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/_b-card.scss";

.card .card-body {
  padding-left: 30px;
}
</style>
