import {hydrate} from "@/utils/form";
import {isNotUndefinedAndNotNull} from "@/utils/classes";

export default class Restriction {
  id = null;
  bookingsCountLimit = 0;
  intervalAsDays = 0;
  target = "subscribers";
  type = 'calendar';
  activities = [];
  daysOfTheWeek = [];
  targetSubscriptionPlans = [];
  timeBlocks = [];
  networkSharingFor = null;
  networkSharingSelectedClubs = [];
  club = JSON.parse(localStorage.getItem('selectedClub'));

  constructor(object = null, options) {
    if (typeof options !== "undefined") {
      if (options.serialize) {
        this.serialize(object);
      }
      if (options.deserialize) {
        hydrate(this, object);
      }
    }
  }

  serialize(object) {
    hydrate(this, object);
    this.castToInt(object);
    this.formatActivities(object);
    this.formatTargetSubscriptionPlans(object);
  }

  castToInt(object) {
    if (object.intervalAsDays) {
      this.intervalAsDays = parseInt(object.intervalAsDays);
    }
    if (object.bookingsCountLimit) {
      this.bookingsCountLimit = parseInt(object.bookingsCountLimit);
    }
  }

  formatTimeBlocks(object) {
    const timeBlocks = [];
    for (const timeBlock of object.timeBlocks) {
      if (isNotUndefinedAndNotNull(timeBlock['@id'])) {
        timeBlocks.push(timeBlock);
      } else {
        timeBlocks.push({
          startAt: timeBlock.startAt.HH + ':' + timeBlock.startAt.mm,
          endAt: timeBlock.endAt.HH + ':' + timeBlock.endAt.mm
        });
      }
    }
    this.timeBlocks = timeBlocks;
  }

  formatTargetSubscriptionPlans(object) {
    const subscriptionPlans = [];

    for (const subscriptionPlan of object.targetSubscriptionPlans) {
      subscriptionPlans.push('/clubs/subscription-plans/' + subscriptionPlan.code);
    }

    this.targetSubscriptionPlans = subscriptionPlans;
  }

  formatActivities(object) {
    const activities = [];

    for (const activity of object.activities) {
      activities.push('/activities/' + activity.code);
    }

    this.activities = activities;
  }
}
