import {_delete, _get, _post, _put, clubId} from "@api/doinsport/services/httpService";

const URI = "/clubs/bookings/restrictions"

export const getRestrictions = () => _get(URI + '?club.id=' + clubId + '&itemsPerPage=500');

export const getRestrictionTimeBlocks= (id) => _get(URI + '/' + id + '/time-blocks');

export const postRestriction= (restriction) => _post(URI, restriction);

export const putRestriction = (restriction) => _put(URI + '/' + restriction.id, restriction);

export const getRestriction = (id) => _get(URI + '/' + id);

export const deleteRestriction = (id) => _delete(URI + '/' + id);
